/* src/app/pages/password-reminder/password-reminder.page.scss */
p:not(.error) {
  color: var(--text-primary) !important;
}
@media screen and (min-width: 624px) {
  p:not(.error) {
    font-size: 20px;
  }
}
.logo-brand {
  width: 70%;
  display: block;
  margin: 15% auto 35px;
}
.logo-brand img {
  width: 100%;
  object-fit: contain;
}
ion-content {
  --background: url("./media/background.jpg") 0 0/100% 100% no-repeat;
}
ion-list {
  --ion-item-background: transparent;
}
ion-list ion-item {
  --ion-item-background: transparent;
}
ion-icon[name=arrow-back] {
  color: var(--text-primary);
}
.logo {
  width: 50%;
  margin: 15% auto 0;
  display: block;
}
.error {
  color: var(--danger) !important;
}
.label-floating {
  color: var(--text-primary);
}
ion-item {
  border-bottom: 1px solid var(--text-primary) !important;
}
a {
  color: #fff;
}
ion-input {
  color: var(--text-primary) !important;
}
.divider {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 700px;
  margin: 0 auto;
}
.striked-text {
  flex: 1;
}
.divider div[flex-grow] {
  flex-grow: 1;
}
.button-md.onboard {
  font-size: 20px;
  font-weight: 100;
  text-transform: none;
  padding: 25px 0;
}
.item-inner {
  box-shadow: none !important;
}
.list-md .item-input {
  border-bottom: 1px solid var(--text-primary);
}
.list-md .item-input:last-child {
  border-bottom: 1px solid var(--text-primary) !important;
  box-shadow: none !important;
}
.list-md[lines=none] .item {
  border-width: 1px;
}
@media screen and (min-width: 624px) {
  .list-md[lines=none] .item {
    font-size: 18px;
  }
}
.ion-md-arrow-back {
  color: var(--text-primary);
}
/*# sourceMappingURL=password-reminder.page.css.map */
