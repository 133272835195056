
<ion-content class="ion-padding">
  <ion-icon (click)="goBack()" name="arrow-back"></ion-icon>
  <div class="divider">
    <div class="logo-brand">
      <img src="../../../custom/logo.png" alt="logo">
    </div>
    <form [formGroup]="formgroup">
      <ion-list lines="none">
        <ion-item>
          <ion-label position="floating">{{'email_address' | translate}}</ion-label>
          <ion-input required formControlName="email" type="text" ></ion-input>
        </ion-item>
        <p *ngIf="formgroup.controls.email.errors && formgroup.controls.email.touched" class="error">{{'password-reminder.email_error' | translate}}</p>
      </ion-list>
    </form>
    <ion-button [disabled]="!formgroup.valid"
            (click)="resetPassword()"
            expand="block"
            class="onboard ion-margin-top">
      {{'password-reminder.button' | translate}}
    </ion-button>
    <p ion-text color="primary" class="ion-text-center">
      {{'password-reminder.description' | translate}}
    </p>
  </div>
</ion-content>
