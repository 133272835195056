import { Component, OnInit } from '@angular/core';
import { NavController, AlertController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ApiService, BroadcastServiceProvider } from '../../services/providers.service';
import { TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-password-reminder',
  templateUrl: './password-reminder.page.html',
  styleUrls: ['./password-reminder.page.scss'],
})
export class PasswordReminderPage implements OnInit {

  formgroup:FormGroup;
  constructor(
      public navCtrl: NavController,
      private apiService: ApiService,
      private broadcastService: BroadcastServiceProvider,
      private translate: TranslateService,
      private alertCtrl: AlertController,
      public formbuilder:FormBuilder,
      ) {

    this.formgroup = this.formbuilder.group({
      email:['', Validators.email]
    });
  }

  ngOnInit() {
      window["app_ga"]('send', 'pageview', "ob_password_reminder");
      window["app_ga"]('send', 'event', "Password reminder", "Entered");
      this.replaceEmailSpaces();
  }
  resetPassword() {
      this.apiService.forgotPassword(this.formgroup.getRawValue()).then(
          async response => {
              let title = this.translate.instant   ('password-reminder.popup.title'),
                  message = this.translate.instant ('password-reminder.popup.message'),
                  confirm = this.translate.instant ('password-reminder.popup.confirm'),
                  alert = await this.alertCtrl.create({
                      header: title,
                      subHeader: message,
                      buttons: [
                          { text: confirm,
                              handler: () => {
                                this.navCtrl.pop();
                              }
                          }
                      ],
                      mode: 'ios'
                  });
              alert.present();
              window["app_ga"]('send', 'event', "Password reminder", "Completed");
          },
          async error2 => {
              let title = this.translate.instant('password-reminder.errors.'+error2.error.error+'.title'),
                  message = this.translate.instant('password-reminder.errors.'+error2.error.error+'.message'),
                  cancel = this.translate.instant('password-reminder.errors.'+error2.error.error+'.cancel'),
                  alert =  await this.alertCtrl.create({
                      header: title,
                      subHeader: message,
                      buttons: [ cancel ],
                      mode: 'ios'
                  });
              alert.present();
          }
      );
  }

  goBack() {
    this.navCtrl.navigateBack('/signin');
  }

  private replaceEmailSpaces(): void {
    this.formgroup.valueChanges
      .subscribe(param => {
        if (param.email && param.email.includes(' ')) {
          this.formgroup.patchValue({email: param.email.replace(' ', '')});
        }
      });
  }

}
